import React from 'react';
import {SPACE_KEY_CODE} from '../constants/keycodes';

export default function useSpaceButtonPress(keyPressHandler) {
    React.useEffect(() => {
        const keyDownHandler = (e) => {
            if (e.keyCode === SPACE_KEY_CODE) {
                keyPressHandler();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }
    }, [keyPressHandler]);
}
