import React from 'react';
import classnames from 'classnames';

import './Card.css';

export default function Card(props) {
    return (
        <div
            className={classnames('card', {
                'card_right': props.isActive && props.variant.comment,
                'card_error': props.isActive && !props.variant.comment,
            })}
            onClick={() => props.onCardClick()}
        >
            <span className='card__number'>{props.number}</span>
            <img className='card__image' src={props.variant.img} alt='question'/>
            {props.showAnswer && <p className='card__comment'>{props.variant.comment}</p>}
        </div>
    )
}
