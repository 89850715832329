import React from 'react';
import {usePrevious} from 'react-use';
import {generateRandomUniqueQuestions} from '../../utils';
import {
    DEFAULT_RIGHT_ANSWERS_COUNT,
    DEFAULT_SLIDE_INDEX,
    QUESTIONS_AMOUNT
} from '../../constants/config';
import {CARDS} from '../../data/cards';
import StartSlide from '../StartSlide';
import QuizSlides from '../QuizSlides';
import ResultSlide from '../ResultSlide';

import './App.css';

const getNewSlides = (previousQuestions = []) => generateRandomUniqueQuestions(QUESTIONS_AMOUNT, CARDS, previousQuestions);

export default function App() {
    const [isQuizStarted, setIsQuizStarted] = React.useState(false);
    const [slides, setSlides] = React.useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(DEFAULT_SLIDE_INDEX);
    const [rightAnswersCount, setRightAnswersCount] = React.useState(DEFAULT_RIGHT_ANSWERS_COUNT);

    const previousSlides = usePrevious(slides);

    const showStartSlide = React.useMemo(() => !isQuizStarted && currentSlideIndex === DEFAULT_SLIDE_INDEX, [currentSlideIndex, isQuizStarted]);
    const showResultSlide = React.useMemo(() => !isQuizStarted && currentSlideIndex === slides?.length - 1, [currentSlideIndex, isQuizStarted, slides?.length]);

    const onQuizStart = React.useCallback(() => {
        setIsQuizStarted(true);
        setSlides(getNewSlides());
    }, []);

    const onQuizRestart = React.useCallback(() => {
        setIsQuizStarted(true);
        setSlides(getNewSlides(previousSlides));
        setCurrentSlideIndex(DEFAULT_SLIDE_INDEX);
        setRightAnswersCount(DEFAULT_RIGHT_ANSWERS_COUNT);
    }, [previousSlides]);

    const onGoToNextSlide = React.useCallback(() => {
        if (currentSlideIndex < slides?.length - 1) {
            setCurrentSlideIndex(prev => prev + 1);
        } else {
            setIsQuizStarted(false);
        }
    }, [currentSlideIndex, slides?.length]);

    return (
        <div className='app'>
            {showStartSlide && <StartSlide onQuizStart={onQuizStart}/>}
            {
                isQuizStarted && slides && (
                    <QuizSlides
                        slide={slides[currentSlideIndex]}
                        currentSlide={currentSlideIndex + 1}
                        slidesTotal={slides.length}
                        onGoToNextSlide={onGoToNextSlide}
                        onRightAnswerClick={() => setRightAnswersCount(prev => prev + 1)}
                    />
                )}
            {showResultSlide && (
                <ResultSlide
                    onQuizRestart={onQuizRestart}
                    rightAnswersCount={rightAnswersCount}
                    questionsCount={slides?.length}
                />
            )}
            <img
                className='app__cover'
                src='/img/slide-bg.png'
                alt='slide'
            />
        </div>
    );
}
