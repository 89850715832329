import React from 'react';
import Card from '../Card';

import './Slide.css';

export default function Slide(props) {
    const [activeCardIndex, setActiveCardIndex] = React.useState(null);

    const onCardClick = React.useCallback((card, cardIndex) => {
        if (props.isAnswered) {
            return;
        }

        setActiveCardIndex(cardIndex);
        props.setIsAnswered(true);

        if (card?.comment) {
            props.onRightAnswerClick();
        }
    }, [props]);

    return (
        <div className='slide'>
            {props.slide?.variants?.map((variant, index) => (
                <Card
                    key={index}
                    variant={variant}
                    number={index + 1}
                    isActive={props.isAnswered && activeCardIndex === index}
                    showAnswer={props.isAnswered}
                    onCardClick={() => onCardClick(variant, index)}
                />
            ))}
        </div>
    )
}
