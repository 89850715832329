import {RANDOM_MIN} from '../constants/config';

const getRandom = (max) => Math.trunc(Math.random() * max) + RANDOM_MIN;

export const generateRandomUniqueQuestions = (amountOfQuestions, allQuestionsList, previousQuestions) => {
    const uniqueRandomQuestionsList = [];

    while (uniqueRandomQuestionsList.length < amountOfQuestions) {
        const randomNumber = getRandom(allQuestionsList?.length);

        const isQuestionInPreviousQuestionsList = previousQuestions?.map(question => question.id)?.includes(randomNumber);
        const isQuestionInResultList = !!uniqueRandomQuestionsList?.find(question => question.id === randomNumber);

        if (!isQuestionInPreviousQuestionsList && !isQuestionInResultList) {
            const newQuestion = allQuestionsList?.find(question => question.id === randomNumber);

            uniqueRandomQuestionsList.push(newQuestion);
        }
    }

    return uniqueRandomQuestionsList;
}
