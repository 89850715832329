export const CARDS = [
    {
        id: 1,
        variants: [
            {
                img: '/img/slides/1-1.png',
                comment: null,
            },
            {
                img: '/img/slides/1-2.png',
                comment: 'Radiobutton позволяет пользователю выбрать только одну опцию из предопределённого набора.',
            },
        ]
    },
    {
        id: 2,
        variants: [
            {
                img: '/img/slides/2-1.png',
                comment: 'Текст на кнопке должен отражать то, что произойдёт при нажатии на неё.',
            },
            {
                img: '/img/slides/2-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 3,
        variants: [
            {
                img: '/img/slides/3-1.png',
                comment: 'Закон Хика: время принятия решения зависит от количества доступных опций. Стоит избегать большого количества вариантов, чтобы пользователь мог быстрее принимать решение.',
            },
            {
                img: '/img/slides/3-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 4,
        variants: [
            {
                img: '/img/slides/4-1.png',
                comment: null,
            },
            {
                img: '/img/slides/4-2.png',
                comment: 'Закон Фиттса: время, затраченное пользователем на достижение цели (перемещение курсора), зависит от расстояния до цели, разделённого на её размер. Кнопки для целевых действий должны быть крупнее обычных кнопок, чтобы пользователь мог быстрее до неё добраться.',
            },
        ]
    },
    {
        id: 5,
        variants: [
            {
                img: '/img/slides/5-1.png',
                comment: 'Чтобы помочь людям с нарушениями зрения различать компоненты, они должны иметь коэффициент цветового контраста не менее 3:1. В примере справа коэффициент текста в кнопке по отношению к её фону менее 3:1, что слишком мало, чтобы чётко обозначить форму кнопки для людей с ослабленным зрением.',
            },
            {
                img: '/img/slides/5-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 6,
        variants: [
            {
                img: '/img/slides/6-1.png',
                comment: null,
            },
            {
                img: '/img/slides/6-2.png',
                comment: 'Не оставляйте пользователя на пустом экране. Помогите ему сделать следующий шаг.',
            },
        ]
    },
    {
        id: 7,
        variants: [
            {
                img: '/img/slides/7-1.png',
                comment: 'Нужно дать понять пользователю, какая именно ошибка, иначе он может запутаться.',
            },
            {
                img: '/img/slides/7-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 8,
        variants: [
            {
                img: '/img/slides/8-1.png',
                comment: null,
            },
            {
                img: '/img/slides/8-2.png',
                comment: 'Часто, но не всегда, важно, чтобы пользователь сперва нашёл нужное значение, а после его показатель.',
            },
        ]
    },
    {
        id: 9,
        variants: [
            {
                img: '/img/slides/9-1.png',
                comment: null,
            },
            {
                img: '/img/slides/9-2.png',
                comment: 'Тёмный UX-паттерн. Задача тёмных паттернов — заставить пользователя купить, скачать, подписаться прямо сейчас. В данном случае путает пользователя и стимулирует нажать на кнопку «Нет», чтобы он дальше получал ненужную ему рассылку.',
            },
        ]
    },
    {
        id: 10,
        variants: [
            {
                img: '/img/slides/10-1.png',
                comment: null,
            },
            {
                img: '/img/slides/10-2.png',
                comment: 'Закон Прагнанца: люди будут воспринимать и интерпретировать неоднозначные или сложные изображения как простейшую возможную форму, потому что это требует от нас наименьших усилий. Согласно исследованиям, пользователи могут лучше понимать и обрабатывать простые фигуры, чем сложные фигуры в дизайне.',
            },
        ]
    },
    {
        id: 11,
        variants: [
            {
                img: '/img/slides/11-1.png',
                comment: 'Поле дата: легче что‑то ввести или выбрать в одном поле, чем нажимать на несколько.',
            },
            {
                img: '/img/slides/11-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 12,
        variants: [
            {
                img: '/img/slides/12-1.png',
                comment: 'В интерфейсе должно быть понятно, какие действия основные, а какие второстепенные.',
            },
            {
                img: '/img/slides/12-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 13,
        variants: [
            {
                img: '/img/slides/13-1.png',
                comment: 'Необходимо выдерживать белое пространство между элементами интерфейса для комфортного восприятия информации.',
            },
            {
                img: '/img/slides/13-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 14,
        variants: [
            {
                img: '/img/slides/14-1.png',
                comment: null,
            },
            {
                img: '/img/slides/14-2.png',
                comment: 'Текст-плейсхолдер помогает пользователю быстрее обнаружить поиск и понять, где он будет происходить. Кнопка «Найти» также должна присутствовать, т.к. не все пользователи могут использовать клавиатуру для подтверждения ввода.',
            },
        ]
    },
    {
        id: 15,
        variants: [
            {
                img: '/img/slides/15-1.png',
                comment: null,
            },
            {
                img: '/img/slides/15-2.png',
                comment: 'Для того, чтобы форма выглядела более эстетично, нужно сохранять оптимальное количество «пустого» пространства между элементами. В примере слева кнопка «Отправить» расположена слишком близко к текстовому полю.',
            },
        ]
    },
    {
        id: 16,
        variants: [
            {
                img: '/img/slides/16-1.png',
                comment: null,
            },
            {
                img: '/img/slides/16-2.png',
                comment: 'Заблокированные кнопки заставляют пользователей думать, что они сделали не так. Если оставить кнопку активной и дать пользователю возможность нажать на неё, то поля с ошибками будут подсвечены, и пользователю не придётся гадать.',
            },
        ]
    },
    {
        id: 17,
        variants: [
            {
                img: '/img/slides/17-1.png',
                comment: 'Основные и второстепенные кнопки  — это сигналы к действию, после которых запускается какой‑то процесс. После нажатия на кнопку «Отмена» не происходит никаких действий. Поэтому, когда пользователи увидят, что кнопка «Отмена» бесцветна, им будет легче распознать в ней возврат к безопасному состоянию.',
            },
            {
                img: '/img/slides/17-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 18,
        variants: [
            {
                img: '/img/slides/18-1.png',
                comment: 'Текст-плейсхолдер должен давать подсказку или показывать формат ввода текста, а не дублировать лейбл.',
            },
            {
                img: '/img/slides/18-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 19,
        variants: [
            {
                img: '/img/slides/19-1.png',
                comment: null,
            },
            {
                img: '/img/slides/19-2.png',
                comment: 'Если радиус скругления карточки и её содержимого внутри не совпадают, это может выглядеть несбалансированно.',
            },
        ]
    },
    {
        id: 20,
        variants: [
            {
                img: '/img/slides/20-1.png',
                comment: null,
            },
            {
                img: '/img/slides/20-2.png',
                comment: 'Тот случай, когда набор строчными буквами оправдан. Это подходит для тегов или статусов — так их проще считать.',
            },
        ]
    },
    {
        id: 21,
        variants: [
            {
                img: '/img/slides/21-1.png',
                comment: 'В хлебных крошках стоит использовать основной цвет для разделителей и предыдущих страниц. Так пользователь сможет понять, что они кликабельны.',
            },
            {
                img: '/img/slides/21-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 22,
        variants: [
            {
                img: '/img/slides/22-1.png',
                comment: 'Не стоит использовать кнопку там, где используются переключатели (toggle). Правильное использование переключателей подразумевает, что их включение/выключение производит немедленный эффект.',
            },
            {
                img: '/img/slides/22-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 23,
        variants: [
            {
                img: '/img/slides/23-1.png',
                comment: null,
            },
            {
                img: '/img/slides/23-2.png',
                comment: 'Для того, чтобы кнопка выглядела гармонично, внутренние отступы кнопки должны быть такие: отступы слева/справа в 2 раза больше отступов сверху/внизу.',
            },
        ]
    },
    {
        id: 24,
        variants: [
            {
                img: '/img/slides/24-1.png',
                comment: null,
            },
            {
                img: '/img/slides/24-2.png',
                comment: 'На тёмном фоне лучше использовать менее насыщенные цвета, т.к. более насыщенные могут заставлять пользователя напрягать глаза и затруднять чтение текста.',
            },
        ]
    },
    {
        id: 25,
        variants: [
            {
                img: '/img/slides/25-1.png',
                comment: 'Подсказки с ошибками лучше всегда сопровождать иконками, т.к. выделение элемента цветом не всегда может быть достаточно для людей с нарушением зрения.',
            },
            {
                img: '/img/slides/25-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 26,
        variants: [
            {
                img: '/img/slides/26-1.png',
                comment: 'Иногда важно не переборщить с количеством используемых методов для группировки элементов. В данном случае использование разделительных линий начинает скорее затруднять восприятие, чем упрощать его.',
            },
            {
                img: '/img/slides/26-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 27,
        variants: [
            {
                img: '/img/slides/27-1.png',
                comment: null,
            },
            {
                img: '/img/slides/27-2.png',
                comment: 'У кнопок с иконками важно сохранять оптическое выравнивание. Иконка не должна слишком сильно прижиматься к тексту, а отступ от иконки до края кнопки чаще всего должен быть меньше, чем отступ от текста до другого края кнопки.',
            },
        ]
    },
    {
        id: 28,
        variants: [
            {
                img: '/img/slides/28-1.png',
                comment: 'Предполагается, что в модальных окнах внимание пользователя следует Z-образному паттерну (принцип Гутенберга), в отличие от F-образного паттерна на страницах, поэтому основная кнопка должна располагаться внизу справа.',
            },
            {
                img: '/img/slides/28-2.png',
                comment: null,
            },
        ]
    },
    {
        id: 29,
        variants: [
            {
                img: '/img/slides/29-1.png',
                comment: null,
            },
            {
                img: '/img/slides/29-2.png',
                comment: 'Оптимальная длина строки для основного текста составляет 50–75 символов.',
            },
        ]
    },
    {
        id: 30,
        variants: [
            {
                img: '/img/slides/30-1.png',
                comment: 'Если основное действие отрицательное, то придавать кнопке, обозначающей необратимое действие, больший визуальный вес опасно, поскольку пользователь может по ошибке выбрать его. Это тот случай, когда лучше заставить пользователя немного подумать перед совершением действия.',
            },
            {
                img: '/img/slides/30-2.png',
                comment: null,
            },
        ]
    },
];
