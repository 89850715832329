import React from 'react';
import classnames from 'classnames';

import './Button.css';

export default function Button(props) {
    return (
        <button
            className={classnames('button', props.className)}
            onClick={() => props.onClick()}
            onKeyDown={e => e.preventDefault()}
        >
            <span className='button__label'>
                {props.label}
            </span>
        </button>
    )
}
