import React from 'react';
import useSpaceButtonPress from '../../hooks/useSpaceButtonPress';
import Button from '../Button';

import './StartSlide.css';

export default function StartSlide(props) {
    useSpaceButtonPress(props.onQuizStart);

    return (
        <div className='start-slide'>
            <Button
                className='start-slide__button'
                onClick={props.onQuizStart}
                label='Начать игру'
            />
        </div>
    )
}
