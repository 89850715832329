import React from 'react';
import useSpaceButtonPress from '../../hooks/useSpaceButtonPress';
import Slide from '../Slide';
import Button from '../Button';

import './QuizSlides.css';

export default function QuizSlides(props) {
    const [isAnswered, setIsAnswered] = React.useState(false);

    const onGoToNextSlide = React.useCallback(() => {
        if (isAnswered) {
            props.onGoToNextSlide();
            setIsAnswered(false);
        }
    }, [isAnswered, props]);

    useSpaceButtonPress(onGoToNextSlide);

    return (
        <div className='quiz-slides'>
            <Slide
                slide={props.slide}
                onRightAnswerClick={props.onRightAnswerClick}
                setIsAnswered={setIsAnswered}
                isAnswered={isAnswered}
            />
            {isAnswered && (
                <Button
                    className='quiz-slides__button'
                    label='Далее'
                    onClick={onGoToNextSlide}
                />
            )}
            <span className='quiz-slides__results'>
                {`${props.currentSlide} / ${props.slidesTotal}`}
            </span>
        </div>
    )
}
