import React from 'react';
import useSpaceButtonPress from '../../hooks/useSpaceButtonPress';
import Button from '../Button';

import './ResultSlide.css';

export default function ResultSlide(props) {
    useSpaceButtonPress(props.onQuizRestart);

    return (
        <div className='results-slide'>
            <div className='results-slide__score'>
                <p className='results-slide__score-title'>
                    Ваш результат:
                </p>
                <p className='results-slide__score-counter'>
                    {`${props.rightAnswersCount} / ${props.questionsCount}`}
                </p>
            </div>
            <Button
                label='Начать новую игру'
                onClick={props.onQuizRestart}
            />
        </div>
    )
}
